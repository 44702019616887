import React from "react"
import { Helmet } from "react-helmet"

import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import * as Design from "../Design"
import CoreLayout from "./Layout"

const header = (title) => (
  <div style={{height: 180}} className={`bg-gray-900`}>
    <div className="w-full max-w-screen-xl mx-auto px-6">
      <div className="pt-16">
        <h1 className="text-4xl text-white">{title}</h1>
      </div>
    </div>
  </div>
)

export default function PageTemplate({ data: { mdx } }) {
  return (
    <CoreLayout header={header(mdx.frontmatter.title)}>
      <Helmet>
        <title>{mdx.frontmatter.title}</title>
        <meta name="description" content="Nested component" />
      </Helmet>
      <div className="py-4">
        <MDXProvider
          components={{
            h1: Design.h1,
            h2: Design.h2,
            h3: Design.h3,
            p: Design.p,
            ul: Design.ul,
            li: Design.li
          }}
        >
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </div>
    </CoreLayout>
  )
}

export const pageQuery = graphql`
  query PostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
      }
    }
  }
`