import React from "react"

const h1 = props => <h1 className="text-4xl" {...props} />
const h2 = props => <h2 className="text-xl" {...props} />
const h3 = props => <h3 className="text-lg" {...props} />
const p = props => <p className="mb-4" {...props} />
const ul = props => <ul className="mb-4" {...props} />
const li = props => <li className="list-decimal" {...props}/>

export {
  h1, h2, h3, p, ul, li
}